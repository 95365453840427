/* html, body {
  margin: 0;
  height: 100%;
  font-family: ;
} */

html, body, #root {
  margin: 0;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} 
